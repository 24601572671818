// list of all internal links

export const GAMES = `https://www.getmega.com/games/`

export const CARDS = `https://www.getmega.com/cards/`
export const CASUAL = `https://www.getmega.com/casual/`
export const TRIVIA = `https://www.getmega.com/trivia/`

export const POKER = `${CARDS}poker/`
export const RUMMY = `${CARDS}rummy/`
export const CARROM = `${CASUAL}carrom/`
export const POOL = `${CASUAL}8-ball-pool/`
export const HOP_POP = `${CASUAL}chinese-checkers-hop-pop/`
export const ABC_RUMMY = `${CASUAL}abcrummy/`
export const DOTS_DASH = `${CASUAL}dots-dash/`
export const WARSHIP = `${CASUAL}warship/`
export const FRUIT_KAAT = `${CASUAL}match-3-fruitkaat/`
export const GKGURU = `${TRIVIA}gk/`
export const ONE23 = `${TRIVIA}123-maths-quiz/`
export const PICME = `${TRIVIA}pic-me/`

export const POKER_LEARN = `${POKER}learn/`
export const POKER_TERMS = `${POKER}terms/`
export const POKER_STRATEGY = `${POKER}strategy/`
export const POKER_BOOKS = `${POKER}books/`

export const CONTACT_US = `https://www.getmega.com/contact-us/`

export const HELP_CENTRE = `https://www.getmega.com/help-centre/`
export const HC_GETTING_STARTED = `${HELP_CENTRE}getting-started/`
export const HC_PAYMENTS_AND_WITHDRAWALS = `${HELP_CENTRE}payments-withdrawals/`
export const HC_KYC = `${HELP_CENTRE}kyc/`
export const HC_CONTESTS_GAMEPLAYS = `${HELP_CENTRE}contests-gameplays/`
export const HC_GAME_RULES = `${HELP_CENTRE}game-rules/`
export const HC_PROMOTIONS_OFFERS = `${HELP_CENTRE}promotions-offers/`

export const FAQS = `https://www.getmega.com/faqs/`
export const FAQS_CARDS = `${FAQS}cards/`
export const FAQS_CASUAL = `${FAQS}casual/`
export const FAQS_TRIVIA = `${FAQS}trivia/`
export const FAQS_CONTESTS = `${FAQS}contests/`
export const FAQS_PAYMENTS_WITHDRAWALS = `${FAQS}payments-withdrawals/`

export const ABOUT_US = `https://www.getmega.com/about-us/`

export const TESTIMONIALS = `https://www.getmega.com/testimonials/`

export const RESPONSIBLE_GAMING = `https://www.getmega.com/responsible-gaming/`

export const CERTIFICATIONS = `https://www.getmega.com/certifications/`

export const PRIVACY_POLICY = `https://www.getmega.com/privacy-policy/`

export const TERMS = `https://www.getmega.com/terms-of-use/`

export const DOWNLOAD = `https://www.getmega.com/install-real-money-gaming-app/`

export const SITEMAP = `https://www.getmega.com/sitemap/`

export const APK_URL = `https://www.getmega.com/latest-social/`
